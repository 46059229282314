.App {
  text-align: center;
}

.App-logo {
  height: 7vmin;
  pointer-events: none;
}

.App-header {
  /* background-color: #E4799F; */
  padding: 25px 0px 25px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.text-purp{
  color: #E4799F;
}
.titlebg{
  background-color: #DE5B8F;
  color: white;
}
.progress_bar{
  background-color: #E4799F;
  /* background-color: black; */


  
  height: 10px;
  transition: width 2s;
}
.progress_bar_bg{
  width: 100%;
  top: 0px;
  left: 0px;
  position: fixed;
  height: 10px;
  background-color: #D2B0FC;
}

.App-link {
  color: #61dafb;
}

.question-text{
  font-size: 25px;
}

.crispyfish{
  margin-top: 40px;
}


.choices-small{
    margin: 0px 100px;  
    background-color:#fff;
    display:grid;
    margin-top: 30px;
    /* position: fixed; */
    /* bottom: 0px; */
}
.choices{
  /* padding: 5px;   */
  background-color:#fff;
  display:grid;
  margin-top: 30px;
  /* position: fixed; */
  width: 100%;
  /* bottom: 0px; */
}

.choices .box, .choices-small .box{
  background-color:#DE5B8F;
  padding:15px;
  /* margin: 5px 0px; */
  /* border-radius:10px; */
  color:#fff;
  display:flex;
  align-items:center;
  justify-content:center;
  font-size:20px;
  font-family:sans-serif;
}

.logo{
  color: #aa3362;
  font-size: xx-large;
}

.selected{
  height: 90px;
  transition: all 1s;
  font-size: 50px !important;
  box-shadow: 0px 0px 20px rgba(0,0,0,0.5);
}



.chaloey{
  width: 100%;
  height: 100%;
  position: fixed;
  color: white;
  z-index: 999999;
  top: 0px;
  left: 0px;
  padding-top: 30px;
}

.chaloey.incorrect{
  background-color: rgb(147, 64, 64);
}

.chaloey.correct{
  background-color: #DE5B8F;
}

.hxch{
  font-size: 40px;
}
.chaloey h3{
  background-color: white;
  
  padding: 20px;
}

.chaloey.incorrect h3{
  color: rgb(181, 3, 3);
}

.chaloey h4{
  margin-top: -20px;
  background-color: rgba(61, 50, 50, 0.5);
  padding: 20px;
}

.chaloey.correct h3{
  color: #DE5B8F;
}

.display-name{
  font-size: 30px;
  padding: 5px;
  text-align: center;
  border-radius: 10px;
  border-style: solid;
}
.display-name-div{
  padding: 20px;
}


.py-3{
  padding-top: 35px;
  padding-bottom: 35px;
}

.btnlight{
  padding: 15px;
  background: rgb(232, 226, 226);
  border-radius: 10px;
  text-decoration: none;
  color: black;
}

.big{
  font-size: 30px;
}

.mt3{
  margin-top:20px;
}
.leaderboard-table{
  width: 100%;
}

.black{
  color: black;
}

table td{
  padding: 5px;
  text-align: left;
}

.gmc-table{
  width:100%;
}
.gmc-table td{
  border-bottom: solid 1px #e0a8d6;
}

.joystick{
  text-align: center;
  padding: 10px;
}

.bt-light{
  background-color: #e0a8d6;
  color: #850b48 !important;
  padding:15px;
  /* margin: 5px 0px; */
  /* border-radius:10px; */
  color:#fff;
  display:flex;
  align-items:center;
  justify-content:center;
  font-size:20px;
  font-family:sans-serif;
}

.you{
  color: yellow;
  text-shadow: 0px 0px 3px rgba(255, 255, 0, 0.5);
  font-weight: bold;
}

.row {
  display: flex;
}

.col {
  flex: 1;
  padding: 20px;
  /* background-color: #f0f0f0; */
  /* border: 1px solid #ccc; */
}


